import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";

import { Link } from "react-scroll";

import Navbar from "../components/Navbar";
import { colors } from "../utils/colors";
import { trackCallClick } from "../utils/misc";
import AppChip from "../components/AppChip";
import AppAccordion from "../components/AppAccordion";
import Contact from "../components/home/Contact";
import Partners from "../components/home/Partners";
import Services from "../components/home/Services";
import Pricing from "../components/home/Pricing";
import Email from "../components/home/Email";
import TechStack from "../components/home/TechStack";
import Technical from "../components/home/Technical";
import Footer from "../components/home/Footer";
import Steppers from "../components/home/Steppers";
import Hero from "../components/home/Hero";
import LayoutButton from "../components/LayoutButton";
import AboutUs from "../components/home/AboutUs";
import Team from "../components/home/Team";
import Testimonials from "../components/home/Testimonial";
import { BookingURL } from "../utils/constant";

const Check = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 10px !important;
  margin-bottom: 20px;
`;

const Faq = styled(Box)`
  background-color: ${colors.gray4};
`;

export default function Home({ setRoute }) {
  const handleBookaCall = (event) => {
    trackCallClick();
  };

  return (
    <div
      style={{
        overflowX: "hidden",
      }}
    >
      <Navbar />
      <Hero setRoute={setRoute} />
      {/* <Partners /> */}
      <Services />
      {/* <Pricing /> */}
      <Email />
      <TechStack />
      <Technical />
      <Testimonials />
      <AboutUs />
      <Team />
      {/* <Testimonials /> */}
      <Box
        sx={{
          paddingTop: { lg: 10, md: 10, sm: 5, xs: 5 },
          backgroundColor: colors.gray4,
        }}
      >
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{
              fontSize: { lg: "2.6rem", md: "31px", sm: "25px", xs: "20px" },
              fontWeight: 600,
              color: colors.fontColor,
              marginBottom: { lg: 4 },
            }}
          >
            Leverage Our Development Expertise in Order to <br />
            Work Better Together
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            sx={{
              color: colors.fontGray,
              marginBottom: 6,
            }}
          >
            Webtimate has skilled developers having 4 to 6 years of experience.
            We have achieved unprecedented levels of customer
            <br />
            satisfaction for 44+ businesses of varying sizes. We ensure
            credibility by helping you accomplish clear business results with
            <br /> our communication and team culture.
          </Typography>
          <Box
            sx={{
              display: "flex",
              columnGap: { lg: 2, md: 2 },
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Box
                component="iframe"
                src=""
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                sx={{
                  height: "315px",
                  width: { lg: "560px", md: "500px", sm: "600px", xs: "350px" },
                  paddingBottom: { xs: 2 },
                }}
              ></Box> */}
              <Box
                component="img"
                sx={{
                  height: "350px",
                  width: { lg: "560px", md: "500px", sm: "600px", xs: "350px" },
                  paddingBottom: { xs: 2 },
                }}
                alt="webtimate-work"
                src={require("../assets/work.jpg")}
              ></Box>

              <LayoutButton
                label="START 15 DAYS RISK-FREE TRAIL"
                topPadding={2}
                bottomPadding={2}
                lg={12}
                md={12}
                sm={12}
                href={BookingURL}
                target="_blank"
                onClick={handleBookaCall}
              />
            </Box>
            <Box
              sx={{
                paddingLeft: { lg: "2.5rem", md: "1.3rem", sm: "1rem" },
                paddingTop: { lg: 0, sm: 0, md: 0, xs: 2 },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 3,
                  color: colors.fontColor,
                  fontWeight: 600,
                }}
              >
                How We Helped Businesses to Scale Up Their Operations
              </Typography>

              <Check>
                <CheckIcon />
                <Typography variant="body" sx={{ color: colors.fontColor }}>
                  Creating the infrastructure to enable scaling (i.e., Creating{" "}
                  <br />
                  systems to be able to manage any number of customers,
                  <br />
                  despite the customer size -Beehive).
                </Typography>
              </Check>

              <Check>
                <CheckIcon />
                <Typography variant="body" sx={{ color: colors.fontColor }}>
                  Updating and refactor code to meet current best
                  <br />
                  practices and standards.
                </Typography>
              </Check>
              <Check>
                <CheckIcon />
                <Typography variant="body" sx={{ color: colors.fontColor }}>
                  Migrating from AWS to GCP.
                </Typography>
              </Check>
              <Check>
                <CheckIcon />
                <Typography variant="body" sx={{ color: colors.fontColor }}>
                  Optimizing costs by migrating, reconciling, and
                  <br />
                  eliminating any unnecessary services.
                </Typography>
              </Check>
              <Check>
                <CheckIcon />
                <Typography variant="body" sx={{ color: colors.fontColor }}>
                  Constantly updating the way we access, store, and utilize
                  <br />
                  the data received from customers via the various APIs.
                </Typography>
              </Check>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.gray,
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              paddingTop: { lg: 15, md: 10, sm: 5, xs: 5 },
              paddingBottom: { lg: 15, md: 10, sm: 5, xs: 5 },
            }}
          >
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "row",
                    xs: "column",
                  },

                  flexWrap: "wrap",
                }}
              >
                <AppChip title="Dedicated Developers" />
                <AppChip title="Agile Methodology" />
                <AppChip
                  title="Large Technical Pool with wider Technology Stack"
                  width="90%"
                  widthMd="50%"
                  widthSm="70%"
                />
                <AppChip
                  title="Faster Resource Accessibility"
                  width="50%"
                  widthMd="30%"
                  widthSm="50%"
                />
                <AppChip title="Flexible Hiring Models" widthSm="40%" />
                <AppChip
                  title="Strict NDA Signed for Complete Privacy"
                  width="90%"
                  widthMd="50%"
                  widthSm="70%"
                />
                <AppChip title="160 Working Hours/Month" />
                <AppChip title="15 Days Risk-Free Trial" />
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    lg: "39px",
                    md: "24px",
                    sm: "1.5rem",
                    xs: "1.5rem",
                  },
                  fontWeight: 600,
                  marginBottom: {
                    lg: "0.875rem",
                    md: "1rem",
                    sm: "2rem",
                    xs: "1rem",
                  },
                  color: colors.fontColor,
                  marginTop: { md: "4rem", sm: "4rem", xs: "2rem" },
                }}
              >
                Benefits With Webtimate
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: colors.fontGray,
                  lineHeight: 1.75,
                  marginBottom: { lg: 2, md: 4, sm: 4, xs: "2rem" },
                }}
              >
                Webtimate has skilled React developers having 4 to 6 years of
                experience. We have achieved unprecedented levels of customer
                satisfaction for 44+ businesses of varying sizes. We ensure
                credibility by helping you accomplish clear business results
                with our communication and team culture.
              </Typography>

              <LayoutButton
                label="BOOK AN APPOINTMENT"
                href={BookingURL}
                target="_blank"
                topPadding={0}
                bottomPadding={0}
                lg={6}
                md={3}
                justifyContent="flex-start"
                onClick={handleBookaCall}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Faq
        id="faq"
        sx={{
          paddingTop: {
            lg: 15,
            md: 10,
            sm: 5,
            xs: 5,
          },
          paddingBottom: {
            lg: 15,
            md: 10,
            sm: 5,
            xs: 5,
          },
        }}
      >
        <Container>
          <Grid container>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              sx={{
                top: 0,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    lg: "2.6rem",
                    md: "2.1rem",
                    sm: "1.6rem",
                    xs: "1.5rem",
                  },
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                  fontWeight: 600,
                  color: colors.fontColor,
                }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              sx={{ marginTop: { lg: 0, md: 0, sm: 3, xs: 3 } }}
            >
              <AppAccordion />
            </Grid>
          </Grid>
        </Container>
      </Faq>
      <Steppers />
      <Contact />
      <Footer />
    </div>
  );
}
