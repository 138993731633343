import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
import { colors } from "../../utils/colors";

const TestimonialContainer = styled(Box)`
  background-color: #f0f8ff;
  padding-bottom: 64px;
  position: relative;
  overflow: hidden;
  padding-top: ${(props) => (props.isMobile ? "34px" : "64px")};
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-top: 40px;
  background-color: white;
  box-shadow: none !important;
  border: none;
  border-radius: 28px !important;
  background-color: #ffffff;
  overflow: visible !important;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;

const AvatarWrapper = styled(Box)`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const NavigationButton = styled(IconButton)`
  background-color: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  z-index: 1;

  &:hover {
    background-color: #f5f5f5 !important;
  }

  &.Mui-disabled {
    background-color: #e0e0e0 !important;
  }

  @media (max-width: 768px) {
    display: none !important;
  }
`;

const PaginationDots = styled(Box)`
  display: none;
  justify-content: center;
  gap: 8px;
  margin-top: 32px; // Increased margin-top for mobile
  position: relative;
  z-index: 3;
  padding: 16px 0;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const PaginationDot = styled.div`
  width: ${(props) => (props.active ? "24px" : "8px")};
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? colors.fontColor : "#D1D5DB")};
  transition: all 0.3s ease;
  cursor: pointer;
`;

const ResponsiveContainer = styled(Container)`
  @media (max-width: 960px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const testimonials = [
  {
    name: "Prerit",
    position: "Senior Data Scientist at Microsoft",
    avatar:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1733814453/prerit_vqrboh.jpg",
    content:
      "I wanted a next.js website created based on figma designs. Abdul and his team proactively asked for any missing information so that the project could be completed on time. They are very well versed with the technology required for web development and used the best in market tools to achieve it. I recommend him for web development projects.",
    rating: 5,
  },
  {
    name: "Rob Percival",
    position: "CEO at Learn Anything",
    avatar:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1733814453/rob_bukbee.jpg",
    content:
      "Abdul has done fantastic work for me, developing refining LLM prompts and training an AI to achieve a high level of accuracy marking student work and providing feedback. I look forward to working with him more in the future.",
    rating: 5,
  },
  {
    name: "Tanay Prajapati",
    position: "Product Manager at Relevel by Unacademy",
    avatar:
      "https://res.cloudinary.com/dzo2ufh6a/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1733814454/tanay_sprcep.jpg",
    content:
      "Abdul has been on toes to get the work done. He has been very specific with timelines throughout the project. He got the tasks done as promised.",
    rating: 5,
  },
];

const TestimonialCard = ({ name, position, avatar, content, rating }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StyledCard>
      <AvatarWrapper>
        <Avatar
          src={avatar}
          sx={{
            width: 80,
            height: 80,
            border: "4px solid white",
          }}
        />
      </AvatarWrapper>
      <CardContent sx={{ pt: 4, pb: 2, px: 3 }}>
        <Rating value={rating} readOnly sx={{ color: "#FFA500", mb: 2 }} />
        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: colors.fontColor + "99",
            fontSize: "0.875rem",
            lineHeight: 1.6,
          }}
        >
          {content}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            color: colors.fontColor,
            fontSize: "1rem",
            mb: 0.5,
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: colors.fontColor + "99",
            fontSize: "0.875rem",
          }}
        >
          {position}
        </Typography>
      </CardContent>
    </StyledCard>
  </Grid>
);

export default function Testimonials() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = isMobile ? 1 : 3;
  const totalPages = Math.ceil(testimonials.length / itemsPerPage);
  const showNavigation = testimonials.length > 3;

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const visibleTestimonials = testimonials.slice(
    currentIndex * itemsPerPage,
    (currentIndex + 1) * itemsPerPage
  );

  return (
    <TestimonialContainer id="testimonials" isMobile={isMobile}>
      <ResponsiveContainer maxWidth="lg">
        <Box
          sx={{
            mb: { xs: 8, sm: 6, md: 8 }, // Increased bottom margin for mobile
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "1.5rem",
                sm: "1.8rem",
                md: "2.2rem",
                lg: "2.4rem",
              },
              fontWeight: 600,
              color: colors.fontColor,
              mb: { xs: 8, sm: 6, md: 8, lg: 10 }, // Increased bottom margin for mobile
            }}
          >
            What Our Clients Say
          </Typography>
        </Box>

        <Box
          sx={{
            position: "relative",
            px: { xs: 0, sm: 4 },
          }}
        >
          <Grid
            container
            spacing={{ xs: 8, sm: 4 }} // Increased spacing for mobile
            sx={{
              mb: { xs: 6, sm: 6, md: 8 }, // Adjusted bottom margin
              position: "relative",
              zIndex: 1,
            }}
          >
            {visibleTestimonials.map((testimonial, index) => (
              <TestimonialCard
                key={currentIndex * itemsPerPage + index}
                {...testimonial}
              />
            ))}
          </Grid>

          {showNavigation && (
            <>
              <NavigationButton
                onClick={handlePrev}
                disabled={currentIndex === 0}
                sx={{
                  position: "absolute",
                  left: { sm: 0, md: -20 },
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <ChevronLeftIcon />
              </NavigationButton>

              <NavigationButton
                onClick={handleNext}
                disabled={currentIndex === totalPages - 1}
                sx={{
                  position: "absolute",
                  right: { sm: 0, md: -20 },
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <ChevronRightIcon />
              </NavigationButton>
            </>
          )}

          <PaginationDots>
            {[...Array(totalPages)].map((_, index) => (
              <PaginationDot
                key={index}
                active={currentIndex === index}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </PaginationDots>
        </Box>
      </ResponsiveContainer>
    </TestimonialContainer>
  );
}
